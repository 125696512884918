import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';
import _ from 'lodash';
import {displayRichText} from '../utils';
import Layout from '../components/layout';
import {usePrivacyPolicy} from '../hooks/queries';
import '../styles/pages/privacy.scss';

const PrivacyPage = ({t}) => {
  const {title, headerTitle, introduction, body} = usePrivacyPolicy();
  const [content, setContent] = useState([]);
  const renderContents = () => (
    <div className="table-of-contents">
      <h2>{t('privacy:contents')}</h2>
      <ul>
        {content.length > 0 &&
          content.slice(1, content.length - 1).map(elem => (
            <li key={elem.id}>
              <a href={`#${elem.id}`}>
                {_.startCase(_.toLower(elem.textContent))}
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
  useEffect(() => {
    const content = Array.from(document.getElementsByTagName('h2'));
    content.forEach(e => {
      e.setAttribute('id', e.textContent.replaceAll(' ', '-').toLowerCase());
    });
    setContent(content);
  }, []);
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <div
        id="privacyPage"
        className="page grid grid-cols-3 tablet:grid-cols-6">
        <div className="col-span-full tablet:col-start-2 tablet:col-end-6">
          <div id="top" />
          <h1 className="desktop:text-4xl text-3xl font-bold">{headerTitle}</h1>
          {renderContents()}
          <div className="desktop:text-xl text-lg">
            {displayRichText(introduction.raw)}
          </div>
          {displayRichText(body.raw)}
        </div>
      </div>
    </Layout>
  );
};

PrivacyPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(PrivacyPage);
